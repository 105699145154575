import React from 'react'
import advertisement01 from '../assets/images/advertisement/01.jpg'
import advertisement02 from '../assets/images/advertisement/02.jpg'
import advertisement03 from '../assets/images/advertisement/03.jpg'
import advertisement04 from '../assets/images/advertisement/04.jpg'
import advertisement05 from '../assets/images/advertisement/05.jpg'
import advertisement06 from '../assets/images/advertisement/06.jpg'
import thumb01 from '../assets/images/advertisement/01.jpg'
import thumb02 from '../assets/images/advertisement/02.jpg'
import thumb03 from '../assets/images/advertisement/03.jpg'
import thumb04 from '../assets/images/advertisement/04.jpg'
import thumb05 from '../assets/images/advertisement/05.jpg'
import thumb06 from '../assets/images/advertisement/06.jpg'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Adnav from '../components/Adnav'


const DEFAULT_IMAGES = [
  {
    id: '1',
    source: advertisement01,
    thumbnail: thumb01,
    caption: 'Photo 1',
    description: 'kyazm - fickle friends',
  },
  {
    id: '2',
    source: advertisement02,
    thumbnail: thumb02,
    caption: 'Photo 2',
    description: 'phai - Arkroyal(feat.gato)',
  },
  {
    id: '3',
    source: advertisement03,
    thumbnail: thumb03,
    caption: 'Photo 3',
    description: 'phai - Fade',
  },
  {
    id: '4',
    source: advertisement04,
    thumbnail: thumb04,
    caption: 'Photo 4',
    description: 'phai - Of course, but I’m not mary jane(feat.MINAKEKKE)',
  },
  {
    id: '5',
    source: advertisement05,
    thumbnail: thumb05,
    caption: 'Photo 5',
    description: 'あの日々の話',
  },
  {
    id: '6',
    source: advertisement06,
    thumbnail: thumb06,
    caption: 'Photo 6',
    description: 'ヴィニルと烏',
  },
]

const siteTitle = 'Onodera Ryo | advertisement'

const AdvertisementAll = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      nonClass: '',
    }
  }
  toggleNon = () => {
    console.log("111");
    this.setState({
      nonClass: 'is-active',
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.toggleNon();
    },700);
  }

  render() {
    return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <div id="main">
        <div className="portrait">
        <nav>
          <p class="nendai">Advertisement</p>
        </nav>
        <div class={`gall ${this.state.nonClass}`}>
          <Gallery
                  images={DEFAULT_IMAGES.map(
                    ({ id, source, thumbnail, caption, description }) => ({
                      source,
                      thumbnail,
                      caption,
                      description,
                    })
                  )}
          />
          </div>
        </div>
      </div>
    </Layout>
    )
  }
}

export default AdvertisementAll