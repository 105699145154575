import React from 'react'

import { Link } from 'gatsby'

const Adnav = ({children}) => (
    <nav>
    <p class="nendai">{children}</p>
    <ul>
      <li><Link to="/advertis-all">-all</Link></li>
    </ul>
    </nav>
)

export default Adnav